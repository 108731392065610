import React, { useState } from 'react';
// import api from './api';
import './App.css';

import ItemRow from './components/ItemRow';

function App() {

  const items = [
    { id: 1, title: "A Moda", type: "Básica", price: 21.90 },
    { id: 2, title: "Calabresa", type: "Básica", price: 21.90 },
    { id: 3, title: "Italiana", type: "Especial", price: 31.90 },
    { id: 4, title: "4 Queijos", type: "Especial", price: 31.90 },
    { id: 5, title: "Palmitos", type: "Especial", price: 31.90 },
    { id: 6, title: "Portuguesa", type: "Especial", price: 31.90 },
  ];

  const [chosen, setChosen] = useState("Todas");

  return (
    <div className="App">
      <header>
        <h1>Cardápio</h1>
      </header>
      <div className="menu">
        <h3 onClick={() => setChosen("Todas")}>Todas</h3>
        <h3 onClick={() => setChosen("Básica")}>Básica</h3>
        <h3 onClick={() => setChosen("Especial")}>Especial</h3>
      </div>
      <div className="container">
        {items.map(item => (
          chosen === "Todas" ?
            <ItemRow key={item.id} id={item.id} title={item.title} price={item.price} />
            : item.type === chosen &&
            <ItemRow key={item.id} id={item.id} title={item.title} price={item.price} />
        ))}
      </div>
    </div>
  );
}

export default App;
