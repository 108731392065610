import React from 'react';

import './styles.css';

const ItemRow = ({ id,title, price }) => {
    return (
        <div className="component">
            <p className="title">{id}. {title}</p>
            <p>................................................................................................</p>
            <p className="price">R$ {price}</p>
        </div>
    );
}
export default ItemRow;
